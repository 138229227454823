import React from 'react';
const About = (props) => (
    <div className="content">
    <p className="big">{props.big}</p>
    <p class="tiny">
    About Me: I Am Abhishek Konar.I am from Aramabagh,Hooghly,West Bengal.I am graduate in B.sc from Burdwan University.I have good knowledge in Web Development as well as in Web Design and Development.My hobby is Photogrphy.Contact No.7001191798
    </p>
    </div>
);

export default About;


    


