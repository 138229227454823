import React from 'react';
import { BrowserRouter,Route } from 'react-router-dom';
import './App.css'
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Connect from './components/Connect';
import Projects from './components/Projects';

function App() {;
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <Route exact path="/" render = {() => <Home big="Hey, Hi There !" medium="Welcome to my website"/>} />
        <Route path="/about" render = {() => <About big="About" />} />
        <Route path="/skills" component ={Skills} />
        <Route path="/connect" component ={Connect} />
        <Route path="/projects" component ={Projects} />
      </div>
    </BrowserRouter>
  );
}

export default App;