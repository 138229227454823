import React from 'react';
import {Link} from 'react-router-dom';
const NavBar = () => (
    <nav className="navbar navbar-expand-lg navbar-dark static-top">
		<div className="container">
			<Link className="navbar-brand" to="/">Home</Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarResponsive">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<Link className="navbar-brand" to="/about">About</Link>
					</li>
					<li className="nav-item">
						<Link className="navbar-brand" to="/skills">Skills</Link>
					</li>
					<li className="nav-item">
						<Link className="navbar-brand" to="/connect">Connect</Link>
					</li>
					<li className="nav-item">
						<Link className="navbar-brand" to="/projects">Projects</Link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
);

export default NavBar;