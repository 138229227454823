import React from 'react';
import {Link} from 'react-router-dom';
const Home = (props) => (
    <div className="content">
        <p className="big">{props.big}</p>
        <p className="medium">{props.medium}</p>
        <Link to="/about" className="glow-on-hover"><input type="button" value="ABOUT"></input></Link>
    </div>
);

export default Home;