import React from 'react';
export default class Projects extends React.Component
{
    render()
    {
        return(
            
        <div className="content">
            <p className="big">Projects</p>
            <a target="_blank" href="https://stackle.onrender.com"><input type="submit" value="PHP-CLOUD"></input></a>
            <a target="_blank" href="https://rainly.onrender.com"><input type="submit" value="REACT-WEATHER"></input></a>
            <a target="_blank" href="https://abhishek.onrender.com"><input type="submit" value="REACT.JS-BLOG"></input></a>
        </div>
            
        );
    }

}

