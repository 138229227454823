import React from "react";

const Skills = () => (
    <div className="content">
        <p className="big">Skills</p>
        <input type="submit" value="HTML"></input>
        <input type="submit" value="CSS"></input>
        <input type="submit" value="BOOTSTRAP"></input>
        <input type="submit" value="JAVASCRIPT"></input>
        <input type="submit" value="PHP"></input>
        <input type="submit" value="MYSQL"></input>
        <input type="submit" value="REACT.JS"></input>
    </div>
);

export default Skills;