import React from 'react';
export default class Connect extends React.Component
{
    render()
        {
            return(
                <div className="content">
                    <p className="big">Connect</p>
                    <a target="_blank" href="https://www.facebook.com/abhishek.konar.79"><img src="https://cdn2.iconfinder.com/data/icons/social-var-1/614/2_-_Facebook-512.png" width="50"></img></a>
                    <a target="_blank" href="https://www.instagram.com/abhishek.konar06/"><img src="https://cdn4.iconfinder.com/data/icons/social-media-2146/512/25_social-512.png" width="50"></img></a>
                    <a target="_blank" href="https://www.linkedin.com/in/abhishek-konar-a902a3198/"><img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-512.png" width="50"></img></a>
                </div>
            );
        }
}




